
<div class="d-flex flex-column">
  <div class="align-self-start">
    <p class="text-warning loading-text">Verkehrsdaten ASTRA</p>
  </div>
  <div class ="align-self-start d-flex align-items-center mx-2 mt-3">
    <div class="spinner-border spinner-border-sm text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-warning ml-3 my-auto">{{ funnySlogan }}</p>
  </div>
  
</div>