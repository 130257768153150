<div id="modal-content-wrapper">
  <header id="modal-header">
    <button class="close" (click)="closeModal()" style="opacity: 0.4">
      <span aria-hidden="true">&times;</span>
    </button>
    <h1 id="modal-title">Richtung {{this.name}} <span class="small ml-2">({{getId()}})</span></h1>
  </header>
  <section id="modal-body" class="d-flex flex-wrap">
    <div class="card">
      <div class="card-body">
        <div *ngFor="let l of site.lanes" class="mt-1">
          <div class="card-text">
            <div *ngIf="l.measurements.reasonForDataError; then error else noError"></div>
            <ng-template #noError>
              <div class=" border border rounded">
                <div class=" p-2">
                  <div class="row">
                    <div class="col-7 d-flex flex-row align-items-start">
                      <p class="my-auto mr-5">{{ getDesignation(l) }}:</p>
                      <img src="assets/images/Car_pictogram.svg.png" height="100%" width="10%" class="ml-10 my-auto">
                      <p class="my-auto mr-2">: {{ getCarAmount(l) }} pro Stunde, </p>
                      <p class="my-auto mr-5"> {{ getCarSpeed(l) }} km/h </p>
                    </div>
                    <div class="col-5 d-flex flex-row align-items-start">
                      <img src="assets/images/Sinnbild_LKW.svg.png" height="100%" width="14%" class="ml-10 my-auto">
                      <p class="my-auto mr-2">: {{ getLorryAmount(l) }} pro Stunde, </p>
                      <p class="my-auto ">{{ getLorrySpeed(l) }} km/h</p>
                    </div>
                  </div>
                </div>
                <app-line-simulator [lane]="l" class="pt-2"></app-line-simulator>
              </div>
            </ng-template>
            <ng-template #error>
              <div class="d-flex flex-row border border-danger align-items-start rounded">
                <p id="no_wrap" class="p-2 my-auto">{{getDesignation(l)}}: </p>
                <img src="/assets/icons/error-7-512.ico" height="20vh" width="20vw" class="my-auto">
                <p class="p-2 my-auto"> Fehlermeldung: {{ l.measurements.reasonForDataError }}</p>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>