<div id="sidebar" class="sidebar">
  <div class="tabs">
    <ul>
      <li>
        <button (mousedown)="changeTab('info')">
          <mat-icon>info_outline</mat-icon>
        </button>
      </li>
      <li>
        <button (mousedown)="changeTab('stats')">
          <mat-icon>bar_chart_outlne</mat-icon>
        </button>
      </li>
      <li>
        <button (mousedown)="changeTab( 'filter')">
          <mat-icon>filter_list_outline</mat-icon>
        </button>
      </li>
      <li>
        <button (mousedown)="changeTab('personal')">
          <mat-icon>person_outline</mat-icon>
        </button>
      </li>
    </ul>
  </div>

  <div class="tab-pane pt-2" *ngIf="currentTab === 'info'">
    <button type="button" class="close" aria-label="Close" (click)="hidePane()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div>
      <div class="header">
        <div class="m-3 d-flex justify-content-between align-items">
          <h1>Wilkommen</h1>
        </div>
      </div>
      <div class="m-3">
        <p>
          Informieren Sie sich auf unserer App, wo es schweizweit aktuell Stau hat. Durch die freizugänglichen Daten
          der <a target="_blank" href="https://opentransportdata.swiss/de/dataset/trafficcounters">Open-Data-Plattform
            Mobilität
            Schweiz</a> haben wir eine
          App
          entwickelt
          in welcher man nur in wenigen Klicks herausfinden kann, welche Route man für die nächste Fahrt vermeiden
          sollte!
        </p>
        <h2>Anwendung</h2>
        <p>Durch Betrachten der Karte erkennen Sie, auf welchen Strecken, es aktuell Stau hat:</p>
        <div class="d-flex flex-row container p-1 my-1 border rounded">
          <div id="blueCircle" class="p-2"></div>
          <p class="p-2 my-auto">Freie fahrt! Die Strasse ist nur leicht befahren.</p>
        </div>
        <div class="d-flex flex-row container p-1 my-1 border rounded">
          <div id="orangeCircle" class="p-2"></div>
          <p class="p-2 my-auto">Augen auf die Strasse, Sie sind nicht alleine! Man fährt hier nicht schneller als
            30km/h</p>
        </div>
        <div class="d-flex flex-row container p-1 my-1 border rounded">
          <div id="redCircle" class="p-2"></div>
          <p class="p-2 my-auto">Es wird eine lange Fahrt. Vermeiden sie diesen Streckenabschnitt, falls möglich. Man
            fährt hier nicht schneller als 10km/h</p>
        </div>
        <p>
          Für mehr Informationen über Ihre gewünschte Strecke, klicken Sie auf die entsprechende Messstation und es
          erscheint ein Pop-up-Fenster mit der allgemeinen Verkehrslage.
          Spezifischere Angaben zur Art des Verkehrs erhalten Sie durch das Klicken auf den erschienenen Knopf
          "Details".
        </p>
      </div>
    </div>
  </div>

  <div class="tab-pane pt-2" *ngIf="currentTab === 'stats'">
    <button type="button" class="close" aria-label="Close" (click)="hidePane()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <div class="header">
        <div class="m-3 d-flex justify-content-between align-items-center">
          <h1>Statistiken</h1>
        </div>
      </div>
      <div class="m-3">
        <p>Hier finden sie einige Zahlen welche aus den aktuellen Messungen berechnet wurden.</p>
        <div (click)="clearAll()" class="filter-button">
          Alle Marker löschen
        </div>
        <h3>Allgemeine Daten</h3>
        <div title="Alle Orte, welche Messtellen in dieselbe Richtung enthalten."
          class="filter-button filter-button-disabled d-flex justify-content-start">
          <span>Anzahl Standorte</span>
          <span class="ml-auto">{{siteCount.toLocaleString()}}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi ml-2 bi-info-circle " viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
        </div>

        <div title="Alle Fahrzeuge welche in der letzten Minute unterwegs waren."
          class="filter-button filter-button-disabled d-flex justify-content-between">
          <span>Anzahl Fahrzeuge</span>
          <span class="ml-auto">{{vehicleCount.toLocaleString()}}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi ml-2 bi-info-circle " viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
        </div>
        <div title="Erhöhte Geschwindigkeit ist grösser als 130 km/h"
          class="filter-button filter-button-disabled d-flex justify-content-between">
          <span>Anzahl Messstellen erhöhter Geschwindigkeit</span>
          <span class="ml-auto">{{countOverSpeedLimit.toLocaleString()}}</span>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi ml-2 bi-info-circle " viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
        </div>
        <h3>Top Messdaten</h3>
        <div (dblclick)="zoomTo(fastest.site)" (click)="focusOn(fastest.site)" title="km/h"
          class="filter-button  d-flex justify-content-between">
          <span>Schnellste Messung</span><span>{{ fastest.site.locationName}} ({{ fastest.value }} km/h)</span>
        </div>
        <div (dblclick)="zoomTo(slowest.site)" (click)="focusOn(slowest.site)" title="km/h"
          class="filter-button  d-flex justify-content-between">
          <span>Langsamste Messung</span><span>{{ slowest.site.locationName}} ({{ slowest.value }} km/h)</span>
        </div>
        <div title="Anzahl Fahrzeuge/h" (dblclick)="zoomTo(mostVehicles.site)" (click)="focusOn(mostVehicles.site)"
          class="filter-button  d-flex justify-content-between">
          <span>Höchster Verkehrsfluss</span><span>{{ mostVehicles.site.locationName}} ({{ mostVehicles.value }}
            Fahrzeuge/h)</span>
        </div>
        <h3>Top 10 schnellste Messungen</h3>
        <div class="d-flex flex-row flex-wrap justify-content-start ">
          <div *ngFor="let t of topTenFastest" class="filter-button  flex-grow-1">
            <div (dblclick)="zoomTo(t.site)" (click)="markTopTen(t.site)">
              <span>{{t.site.locationName}} </span> <span> ({{t.value}} km/h)</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div id="filter" class="tab-pane pt-2" *ngIf="currentTab === 'filter'">
    <button type="button" class="close" aria-label="Close" (click)="hidePane()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <div class="header">
        <div class="m-3 d-flex justify-content-between  align-items-center">
          <h1>Filter</h1>
        </div>
      </div>
      <div class="m-3">
        <h2 class="my-3">Messstellen</h2>
        <div class="d-flex justify-content-center wrap grow">
          <div (mousedown)="onFilterClick('normal')" class="filter-button " id="normal">
            Normaler Verkehr
          </div>
          <div (mousedown)="onFilterClick('fehlerhaft')" class="filter-button " id="fehlerhaft">
            Fehlerhafte Messungen
          </div>
          <div (mousedown)="onFilterClick('stau')" class="filter-button " id="stau">Stau</div>
          <div (mousedown)="onFilterClick('stockend')" class="filter-button " id="stockend">
            Stockender Verkehr
          </div>
        </div>
        <h2 class="my-3">Karten</h2>
        <div class="d-flex flex-row flex-wrap justify-content-center">
          <div (mousedown)="onMapClick('swisstopo')" class="map-button m-1">
            <div class="map-button-text d-flex justify-content-center align-items-center">
              <h3>Swiss Topo</h3>
            </div>
            <img src="/assets/images/swisstopo.png">
          </div>
          <div (mousedown)="onMapClick('opentopo')" class="map-button m-1">
            <div class="map-button-text d-flex justify-content-center align-items-center">
              <h3>Open Topo Map</h3>
            </div>
            <img src="/assets/images/opentopo.png">
          </div>
          <div (mousedown)="onMapClick('osm')" class="map-button m-1">
            <div class="map-button-text d-flex justify-content-center align-items-center">
              <h3>Open Street Map</h3>
            </div>
            <img src="/assets/images/osm.png">
          </div>
          <div (mousedown)="onMapClick('stadia')" class="map-button m-1">
            <div class="map-button-text d-flex justify-content-center align-items-center">
              <h3>Stadia</h3>
            </div>
            <img src="/assets/images/stadia.png">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane pt-2" *ngIf="currentTab === 'personal'">
    <button class="close" aria-label="Close" (click)="hidePane()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container">
      <div class="header">
        <div class="mr-1 d-flex justify-content-between align-items-center">
          <h1>Informationen</h1>
        </div>
      </div>
      <p>Diese App wurde im Frühlingssemester 2021 im Rahmen der Open Data Vorlesung an der Universität Bern
        erstellt.</p>
      <h2>Daten</h2>
      <p>Die Daten werden jede Minute automatisch von der Seite <a taget="_blank"
          href="https://opentransportdata.swiss/de/dataset/trafficcounters"> opentransportdata.swiss </a> geholt.</p>
      <h2>Source Code</h2>
      <p>Im unserem <a href="https://github.com/m-cirkovic/Open-Data_ASTRA">Github Repository</a> ist der Quellcode zum
        Downloaden zu finden.</p>
      <h2>Data Coach</h2>
      <p>Das Projekt wurde von <a target="_blank" href="https://ch.linkedin.com/in/valentino-scarcia-33746b87">
          Valentino Scarcia </a>, dem Projektleiter der ITS-Innovationen Abteilung beim Bundesamt für Strassen ASTRA,
        begleitet. </p>
      <h2>Entwickler</h2>
      <div class="card-deck">
        <div class="card">
          <img class="card-img-top" src="/assets/images/manuel.jpg" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title">Manuel Schüpbach</h4>
            <p class="card-text">BSc Computer Science</p>
            <a href="mailto:manuel.schuepbach@inpeek.ch">E-mail</a> |
            <a target="_blank" href="https://github.com/maschuep">GitHub</a>
          </div>
        </div>
        <div class="card">
          <img class="card-img-top" src="/assets/images/marko.jpg" alt="Card image cap">
          <div class="card-body">
            <h4 class="card-title">Marko Cirkovic</h4>
            <p class="card-text">BSc Computer Science</p>
            <a href="mailto:markocirkovic1999@gmail.com">E-mail</a> |
            <a target="_blank" href="https://github.com/m-cirkovic">GitHub</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>