<div id="timebar" class="timebar d-flex p-1">
    <p *ngIf="!current()" class="my-auto font-weight-bold">{{ date() | date:'dd.MM.YY HH:mm' }}</p>
    <p *ngIf="current()" class="my-auto font-weight-bold">{{ date() | date:'HH:mm' }} (aktuell)</p>

    <div (click)="changeData()" class="ml-2 tb-button my-auto d-flex flex-row" id="livebutton">
        <span *ngIf="!loading" class="my-auto">Live</span>
        <div *ngIf="loading" class="my-auto">
            <div class="spinner-border spinner-border-sm text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

    </div>
</div>